import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'play'
    },
    {
        path: '/play',
        name: 'Play',
        component: () => import('../views/Play'),
        children: [
            {path: '', component: () => import('../views/puzzles/Seasons')},
            // Seasons
            {
                path: 'season-1',
                name: 'play/season-1',
                component: () => import('../views/RouteRepeater'),
                children: [
                    {path: '', component: () => import('../views/puzzles/season-1/Levels')},
                    {
                        path: 'level-1',
                        name: 'play/season-1/level-1',
                        component: () => import('../views/puzzles/season-1/Level1')
                    },
                    {
                        path: 'level-2',
                        name: 'play/season-1/level-2',
                        component: () => import('../views/puzzles/season-1/Level2')
                    }
                ]
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
