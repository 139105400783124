<template>
  <div id="app" class="d-flex flex-column vh-100 bg-light">
    <header-bar/>

    <div class="flex-grow-1 d-flex justify-content-center align-items-center">
      <router-view/>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar";

export default {
  components: {HeaderBar}
}
</script>