import Vue from 'vue'

import {BootstrapVue} from 'bootstrap-vue'

Vue.use(BootstrapVue)

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'

library.add(fas);
library.add(far);
library.add(fab);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layer', FontAwesomeLayers);
Vue.component('fa-text', FontAwesomeLayersText);

import './main.scss'

import App from './App.vue'


import router from './router'
import store from './store'

window.$app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
